import React, { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import SmallLoader from "../common/SmallLoader";
import Papa from "papaparse";
import axios from "axios";
import { awsUrl2, versionNumber } from "../../config";
import { getHeader } from "../../utils/common";
import { Auth } from "aws-amplify";

import { CSVLink } from "react-csv";
import AutomationTesting from "./AutomationTesting";
import ManualRate from "./ManualRate";
const csvjson = require("csvjson");
const uuidv4 = require("uuid/v4");
const state_map = require("../../utils/state_map.json");

const carrierMap = {
  carrier_a: "Extensis-CHUBB",
  carrier_b: "Extensis-SAIC",
  carrier_c: "Pride-Lion",
  carrier_d: "Pride-SNIC",
  carrier_e: "Security-National-Insurance-Company",
  carrier_f: "Technology-Insurance-Company",
  carrier_g: "Amtrust-Insurance-Company-Of-Kansas",
  carrier_h: "Wesco-Insurance-Company",
  carrier_i: "Rochdale-Insurance-Company",
  carrier_j: "CHUBB",
  carrier_k: "SAIC",
  carrier_l: "Lion",
  carrier_m: "SNIC",
  carrier_n: "Solve-Tmic",
  carrier_o: "State-Fund",
  carrier_p: "choice-amtrust-2022",
  carrier_q: "Fourth-Tic",
  carrier_r: "Key Risk Insurance Company",
  carrier_s: "United Wisconsin Insurance Company",
  carrier_t: "Hemic-Tier1",
  carrier_u: "Hemic-Tier2",
  carrier_v: "Hemic-Tier3",
  carrier_w: "Hemic-Tier4",
  carrier_x: "Hemic-Tier5",
  carrier_y: "Hemic-Tier6",
  carrier_z: "Berkley Casualty Company",
  carrier_aa: "Carolina Casualty Insurance Company",
  carrier_ab: "Midwest Employers Casualty Company",
  carrier_ac: "Starnet Insurance Company",
  carrier_ad: "Preferred Employers Insurance Company",
  carrier_ae: "Sunz Insurance Company",
  carrier_af: "Prescient National Insurance Company",
  carrier_ag: "Demo $25K Ded",
  carrier_ah: "Demo $50K Ded",
  carrier_ai: "Demo $75K Ded",
  carrier_aj: "Demo $100K Ded",
  carrier_ak: "Demo $250K Ded",
  carrier_al: "Demo $500K Ded",
  carrier_am: "Demo $1M Ded",
  carrier_an: "Demo $10K Ded",
  carrier_ao: "New Hampshire Insurance Company(AIG)",
  carrier_ap: "Nationwide",
  carrier_aq: "Normandy",
  carrier_ar: "American Zurich Insurance Company",
  carrier_as: "Zurich American Insurance Company",
  carrier_at: "Zurich American Insurance Company Of Illinois",
  carrier_au: "Benchmark",
  carrier_av: "SUNZ 250K",
  carrier_aw: "UWIC 250K",
  carrier_ax: "Service Llyods",
  carrier_ay: "UWIC EOR",
  carrier_az: "E3-ARCH",
  carrier_ba: "E3-SUNZ",
  carrier_bb: "E3-SAIC",
  carrier_bc: "E3-PNIC (Prescient)",
  // carrier_e: "Security National Insurance Company",
  // carrier_f: "Technology Insurance Company",
  // carrier_g: "Amtrust Insurance Company of Kansas",
  // carrier_s: "United Wisconsin Insurance Company",
  // carrier_h: "Wesco Insurance Company",
  // carrier_i: "Rochdale Insurance Company",
  // carrier_r: "Key Risk Insurance Company",
};

const instanceSpecificTables = ["fundRate"];

class PanelCompliance extends Component {
  constructor(props) {
    super(props);
    this.downloadBtn = React.createRef();
    this.state = {
      error: false,
      validationDone: false,
      tableName: "eligibility",
      downloadDataList: [
        { a: 1, b: 2 },
        { a: 2, b: 3 },
      ],
      downloadLinkActive: false,
      downloadedTableName: "",
      buttonToggle: true,
      loggedinUser: "",
    };
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
    let userRole;
    Auth.currentSession().then((res) => {
      let email = res.accessToken.payload.username;
      let userName = res?.idToken?.payload?.name || "";
      if (userName) {
        this.setState({ loggedinUser: userName });
      }

      userRole = res.accessToken.payload["cognito:groups"];
      this.setState({ userRole, email });
      if (userRole.includes("Compliance")) {
        this.getCarrierName();
      }
    });
  }

  getTableName = (e) => {
    let tableName = e.target.value;
    const { csvTextArray } = this.state;
    if (csvTextArray) {
      try {
        // let textValidation = this.validateFileText(csvTextArray, tableName);

        // if (textValidation.length === 0) {
        this.setState({
          textValidation: false,
          error: false,
          validationDone: true,
        });
        // } else {
        //   this.setState({
        //     textValidation,
        //     error: true,
        //     errorMessage: `Data is not valid. ${textValidation.length} rows have issues`,
        //     validationDone: false,
        //   });
        // }
      } catch (e) {
        console.log(e);
        this.setState({
          textValidation: false,
          error: true,
          errorMessage: "Please select a valid CSV file",
          validationDone: false,
        });
      }
    }
    this.setState({
      tableName: tableName,
    });
  };

  readFile = (file) => {
    let fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = () => {
        // let txt = fileReader.result;
        resolve(fileReader.result);
      };
      fileReader.readAsText(file);
    });
  };

  parseFileText = (text) => {
    let papa = Papa.parse(text, { header: true });
    return papa.data;
  };

  validateFileText = (array, tableName) => {
    const { userRole } = this.state;
    let headers, dateHeadersList, response, uniqureResponseSet, dataValidator;
    let keyCheckMap = {};
    let numHeadersList = [];
    let numExceptionValuesList = [];

    if (tableName === "eligibility") {
      headers = [
        "class_code",
        "state",
        "description",
        "eligibility",
        "hazard",
        "naics",
        "sic",
      ];
      numHeadersList = ["class_code"];
      keyCheckMap["eligibility"] = ["excluded", "restricted", "approved"];
      // keyCheckMap["key"] = ["key", "non_key"];
      if (userRole.includes("ComplianceAdmin")) {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "lcm") {
      headers = ["lcm", "lcm_date", "state"];
      dateHeadersList = ["lcm_date"];
      numHeadersList = ["lcm"];
      if (userRole.includes("ComplianceAdmin")) {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "generalFactor") {
      headers = ["value", "effective_date", "state", "factor_name"];
      dateHeadersList = ["effective_date"];
      numHeadersList = ["value"];
      numExceptionValuesList = ["ncci", "nycirb", "wi", "njcrib"];
      if (userRole.includes("ComplianceAdmin")) {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "adoption") {
      headers = ["lc_date", "adoption_date", "state"];
      dateHeadersList = ["lc_date", "adoption_date"];
      if (userRole.includes("ComplianceAdmin")) {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "lossCost") {
      headers = [
        "lc_date",
        "class_alpha",
        "class_code",
        "state",
        "loss_cost",
        "d_ratio",
        "elr_ratio",
      ];
      dateHeadersList = ["lc_date"];
      numHeadersList = ["loss_cost", "class_code"];
    } else if (tableName === "stateSpecific") {
      headers = ["effective_date", "factor_name", "state", "value"];
      dateHeadersList = ["effective_date"];
      numHeadersList = ["value"];
    } else if (tableName === "limits") {
      headers = [
        "effective_date",
        "afela",
        "afela_min",
        "state",
        "min_prem",
        "rate",
        "el_limits",
      ];
      dateHeadersList = ["effective_date"];
    } else if (tableName === "taxes") {
      headers = ["effective_date", "state", "description", "factor", "value"];
      dateHeadersList = ["effective_date"];
      numHeadersList = ["value"];
    }

    dataValidator = this.dataValidator(
      array,
      headers,
      dateHeadersList,
      keyCheckMap,
      numHeadersList,
      numExceptionValuesList
    );
    return dataValidator;
  };

  dataValidator = (
    array,
    headers,
    dateHeadersList,
    keyCheckMap,
    numHeadersList,
    numExceptionValuesList
  ) => {
    let missingDataIndexList = [];
    if (!dateHeadersList) {
      dateHeadersList = [];
    }
    let index = 0;
    for (let dict of array) {
      delete dict[""];
      let haveError = false;
      let keys = Object.keys(dict);
      // let values = Object.values(dict);
      for (let key in dict) {
        if (headers.includes(key) && (dict[key] === "" || !dict[key])) {
          console.log("++ here1");
          if (!haveError) {
            missingDataIndexList.push(dict);
            haveError = true;
          }
        }
      }
      for (let header of headers) {
        if (!keys.includes(header) && index !== array.length) {
          console.log("++ here2");
          if (!haveError) {
            missingDataIndexList.push(dict);
            haveError = true;
          }
        }
        if (keyCheckMap[header] && index !== array.length) {
          if (!keyCheckMap[header].includes(dict[header])) {
            console.log("++ here3");
            if (!haveError) {
              missingDataIndexList.push(dict);
              haveError = true;
            }
          }
        }
        if (numHeadersList.includes(header)) {
          if (isNaN(dict[header])) {
            // if (
            // 	!numExceptionValuesList.includes(
            // 		dict[header]
            // 	)
            // ) {
            console.log("++ here4");
            if (!haveError) {
              missingDataIndexList.push(dict);
              haveError = true;
            }
            // }
          }
        }
        if (dateHeadersList.includes(header)) {
          let date = dict[header];
          if (
            !moment(date, "M-D-YYYY", true).isValid() &&
            !moment(date, "M/D/YYYY", true).isValid() &&
            index !== array.length - 1
          ) {
            console.log("++ here5");
            if (!haveError) {
              missingDataIndexList.push(dict);
              haveError = true;
            }
          }
        }
      }
      if (haveError) {
        dict.delete = true;
      }
      index++;
    }

    return missingDataIndexList;
  };

  validateFormat = async (e) => {
    const { tableName } = this.state;
    this.setState({ fileUploadSuccess: false });
    try {
      e.persist();
      let fileType = e.target.files[0].name.split(".").reverse()[0];
      let files = e.target.files[0];
      if (fileType === "csv") {
        // see if the file has any text
        let text = await this.readFile(files);
        if (text.trim()) {
          // now convert the text to array of objects
          let parsedData = this.parseFileText(text);
          parsedData.length--;
          // if (parsedData.length < 100) {
          // let textValidation = this.validateFileText(parsedData, tableName);

          this.setState({
            textValidation: false,
            csvTextArray: parsedData,
            error: false,
            validationDone: true,
          });
        } else {
          this.setState({
            textValidation: false,
            error: true,
            errorMessage: "This file is empty. Select a valid file.",
            validationDone: false,
          });
        }
      } else {
        this.setState({
          textValidation: false,
          error: true,
          errorMessage: "Please select a valid CSV file",
          validationDone: false,
        });
      }
    } catch (error) {
      console.log("error", error);
      this.setState({
        textValidation: false,
        error: true,
        errorMessage: "Error in selected file",
        validationDone: false,
      });
    }
  };

  uploadFileS3 = async (fileData, postDataS3) => {
    return new Promise(async (resolve, reject) => {
      const header = await getHeader();

      await axios
        .post(awsUrl2 + "/api/compFile/file", postDataS3, header)
        .then(async (response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.url;
          let options = {
            header: { "Content-Type": "csv" },
          };
          const csvData = csvjson.toCSV(fileData, { headers: "key" });
          await axios
            .put(lossSignedURL, csvData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve(returnData.url);
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  uploadFile = async (e) => {
    const { validationDone, userName, loggedinUser } = this.state;
    this.setState({ fileUploadSuccess: false });
    if (validationDone) {
      this.setState({ showLoaderUpload: true });
      const { csvTextArray } = this.state;
      // get the name of the table and upload the data to that table
      let { tableName, userRole, email } = this.state;
      const header = await getHeader();

      if (instanceSpecificTables.includes(tableName)) {
        if (this.state.InstanceName) {
          tableName = `${this.state.InstanceName}${tableName}`;
        } else {
          this.setState({ instanceTypeRequired: true });
          return;
        }
      }

      console.log(tableName);

      this.setState({
        showLoaderUpload: true,
        stateCarrierRequired: false,
        instanceTypeRequired: false,
      });

      if (csvTextArray) {
        let body = {
          data: csvTextArray,
        };
        let uuid = uuidv4();

        let url = await this.uploadFileS3(csvTextArray, {
          fileName: `${uuid}.csv`,
        });

        console.log(url);
        let response_comp;
        await axios
          .post(
            awsUrl2 + "/api/compFile/put",
            JSON.stringify({
              uuid,
              tableName,
              url,
              groups: userRole.includes("Compliance")
                ? ["Compliance"]
                : ["ComplianceAdmin"],
              userName: email?.split("@")?.[0] || "",
              timeStamp: Date.now(),
              type: "upload",
              carrier: userName,
              emailTo: email,
            }),
            header
          )
          .then(async (res) => {
            while (!response_comp) {
              await axios
                .post(
                  awsUrl2 + "/api/compFile/get",
                  JSON.stringify({
                    uuid,
                    tableName,
                  }),
                  header
                )
                .then((res) => {
                  let data = res.data[0];
                  console.log("-data: ", data);
                  if (data.url) {
                    response_comp = data.status;
                  }
                })
                .catch((err) => {
                  console.log("error: ", err);
                });

              let timeID = await new Promise((resolve, reject) => {
                let myTimeout = setTimeout(() => {
                  resolve(myTimeout);
                }, 5000);
              });
              clearTimeout(timeID);
            }

            if (response_comp !== "SUCCESS") {
              throw new Error("Did't Receive the Response as SUCCESS");
            } else {
              this.setState({
                showLoaderUpload: false,
                fileUploadSuccess: response_comp ? true : false,
              });
              if (response_comp) {
                debugger;
                let req_body = {
                  uploaderName: loggedinUser,
                  tableName: tableName,
                };
                axios
                  .post(awsUrl2 + "/api/sendUploadEmail", req_body)
                  .then((res) => {
                    console.log("Uploaded!!");
                  })
                  .catch((error) => {
                    console.log("Error :", error);
                  });
              }
            }
            if (response_comp.toLowerCase().includes("error")) {
              console.log("error in upload");
            }
          })
          .catch((error) => {
            console.log("Error in api", error);

            if (response_comp !== "SUCCESS") {
              let carrierNameList = [];
              for (let key of response_comp) {
                carrierNameList.push(key["carrier"]);
              }

              let removeDuplicateCarrierNameList = new Set(carrierNameList);
              let finalCarrierNotUploaded = [...removeDuplicateCarrierNameList];

              let listOfErrorCarrierName = finalCarrierNotUploaded.map(
                (key, index) => <p key={index}>{key}</p>
              );

              this.setState({
                showLoaderUpload: false,
                error: true,
                errorMessage:
                  "An error occurred during the CSV file upload. The following list contains the carriers that were not successfully ingested:",
                listOfRowError: listOfErrorCarrierName,
              });
            } else {
              this.setState({
                showLoaderUpload: false,
                error: true,
                errorMessage:
                  "Error in uploading CSV file. Please contact admin@insurecomp.com for details.",
              });
            }
          });

        // await axios
        //   .post(awsUrl2 + apiEndPoint, JSON.stringify(body), header)
        //   .then((res) => {
        //     this.setState({ showLoaderUpload: false, fileUploadSuccess: true });
        //     if (res.data.not_uploaded && res.data.not_uploaded.length > 0) {
        //       this.setState({
        //         error: true,
        //         errorMessage: "Some rows were not updated.",
        //         textValidation: res.data.not_uploaded,
        //       });
        //     }
        //     console.log(res);
        //   })
        //   .catch((err) => {
        //     this.setState({
        //       showLoaderUpload: false,
        //       error: true,
        //       errorMessage:
        //         "Error in uploading CSV file. Please contact admin@insurecomp.com for details.",
        //     });
        //     console.log(err);
        //   });
      }
    } else {
      this.setState({
        error: true,
        errorMessage: "Please select a valid CSV file",
      });
    }
  };

  getCarrierName = async () => {
    Auth.currentSession().then((res) => {
      let userName = res.accessToken.payload.username.split("@")[0];
      this.setState({
        userName: userName,
      });
      console.log("Logging the user name from compliance: " + userName);
    });
  };

  downloadFile = async (event) => {
    let {
      tableName,
      userName,
      userRole,
      eligibilityState,
      eligibilityCarrier,
      email,
    } = this.state;
    if (
      tableName === "eligibility" &&
      (!eligibilityCarrier || !eligibilityState)
    ) {
      this.setState({ stateCarrierRequired: true });
      return;
    }

    if (instanceSpecificTables.includes(tableName)) {
      if (this.state.InstanceName) {
        tableName = `${this.state.InstanceName}${tableName}`;
      } else {
        this.setState({ instanceTypeRequired: true });
        return;
      }
    }

    console.log(tableName);

    this.setState({
      showLoaderDownload: true,
      stateCarrierRequired: false,
      instanceTypeRequired: false,
    });
    let apiEndPoint;

    apiEndPoint = userRole?.includes("Compliance")
      ? "/api/compFileDownload/" + tableName + "/" + userName
      : "/api/compFileDownload/" + tableName;
    let uuid = uuidv4();
    const header = await getHeader();
    console.log("header: ", header);

    await axios
      .post(
        awsUrl2 + "/api/compFile/put",
        JSON.stringify({
          uuid,
          tableName,
          groups: userRole?.includes("Compliance")
            ? ["Compliance"]
            : ["ComplianceAdmin"],
          userName: email?.split("@")?.[0] || "",
          timeStamp: Date.now(),
          type: "download",
          carrier: userName | "",
          eligibilityState,
          eligibilityCarrier,
        }),
        header
      )
      .then(async (res) => {
        console.log("res: ", res);

        let signURL, status;

        while (!signURL) {
          await axios
            .post(
              awsUrl2 + "/api/compFile/get",
              JSON.stringify({
                uuid,
                tableName,
              }),
              header
            )
            .then((res) => {
              let data = res.data[0];
              console.log("-data: ", data);
              if (data.signURL || data.status) {
                signURL = data.signURL;
                status = data.status;
              }
            })
            .catch((err) => {
              console.log("error: ", err);
            });

          let timeID = await new Promise((resolve, reject) => {
            let myTimeout = setTimeout(() => {
              resolve(myTimeout);
            }, 5000);
          });
          clearTimeout(timeID);
        }

        console.log("status for download: ", status);

        if (signURL) {
          await axios
            .get(signURL)
            .then((res2) => {
              this.setState(
                {
                  downloadDataList: res2.data,
                  downloadLinkActive: true,
                  downloadedTableName: tableName,
                },
                () => {
                  setTimeout(() => {
                    if (this.isCsvFileReady()) {
                      this.setState({ showLoaderDownload: false });
                      this.downloadBtn.current.link.click();
                    }
                  }, 3000);
                }
              );
            })
            .catch((error) => {
              this.setState({
                showLoaderDownload: false,
                errorMessage: "Error while downloading file!",
              });
              console.log(error);
            });
        }
      })
      .catch((err) => {
        this.setState({
          showLoaderDownload: false,
          errorMessage: "Error while downloading file!",
        });
        console.log(err);
      });
  };

  isCsvFileReady = () => {
    return (
      this.downloadBtn &&
      this.downloadBtn.current &&
      this.downloadBtn.current.link &&
      this.downloadBtn.current.link.click &&
      typeof this.downloadBtn.current.link.click === "function"
    );
  };

  downloadTxtFile = (textValidation) => {
    let data = [];
    let othersFlag = false;
    let errorListLength = textValidation.length;
    if (errorListLength > 50) {
      textValidation.length = 50;
      othersFlag = true;
    }
    data = textValidation.reduce((r, a) => r.concat(a + 2, ","), []);
    if (othersFlag) {
      data.push(`... and ${errorListLength - 50} other(s)`);
    }
    const element = document.createElement("a");
    const file = new Blob(data, {
      type: "text/plain",
      endings: "native",
    });
    element.href = URL.createObjectURL(file);
    element.download = "invalidRows.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  render() {
    const {
      error,
      errorMessage,
      downloadDataList,
      downloadLinkActive,
      showLoaderDownload,
      showLoaderUpload,
      textValidation,
      validationDone,
      fileUploadSuccess,
      userRole,
      downloadedTableName,
      tableName,
      stateCarrierRequired,
      instanceTypeRequired,
      buttonToggle,
      listOfRowError,
    } = this.state;
    const stateListDropDown = () => {
      return (
        <>
          <b>
            {"State"}
            <span className="mandate">*</span>
          </b>
          <select
            id="state-type"
            style={{ width: "7rem" }}
            onChange={(e) => {
              this.setState({
                eligibilityState: e.target.value,
                stateCarrierRequired: false,
              });
            }}
          >
            <option selected disabled value={"Please Select"}>
              {"Please Select"}
            </option>
            {[
              "AK",
              "AL",
              "AR",
              "AZ",
              "CA",
              "CO",
              "CT",
              "DC",
              "DE",
              "FL",
              "GA",
              "HI",
              "IA",
              "ID",
              "IL",
              "IN",
              "KS",
              "KY",
              "LA",
              "MA",
              "MD",
              "ME",
              "MI",
              "MN",
              "MO",
              "MS",
              "MT",
              "NC",
              "ND",
              "NE",
              "NH",
              "NJ",
              "NM",
              "NV",
              "NY",
              "OH",
              "OK",
              "OR",
              "PA",
              "RI",
              "SC",
              "SD",
              "TN",
              "TX",
              "UT",
              "VA",
              "VT",
              "WA",
              "WI",
              "WV",
              "WY",
            ].map((state) => {
              return <option value={state}>{state}</option>;
            })}
          </select>
        </>
      );
    };

    return (
      <div>
        <Navbar />
        <button
          className="mr-5 mt-5 border-0 "
          style={{ float: "right" }}
          onClick={() => {
            this.setState({ buttonToggle: !buttonToggle });
          }}
        >
          {userRole &&
            !userRole.includes("ManualRateBeta") &&
            (buttonToggle ? "Automation Portal" : "Compliance Portal")}{" "}
        </button>
        {buttonToggle ? (
          <div className="tab-content tab-content-compliance">
            <div
              id="compliancePortal"
              className="container m-auto"
              style={{ minHeight: "70vh" }}
            >
              <h3 className="text-center mt-5">
                COMPLIANCE INPUTS <h5>(ver. {versionNumber})</h5>
              </h3>
              <div className="card my-5 mx-auto" style={{ maxWidth: "600px" }}>
                <div className="card-body">
                  <form>
                    <div>
                      Table<span className="mandate">*</span>
                      <span className="errMsg" />
                      <div className="d-flex file-selector">
                        <select
                          id="businessTypeId"
                          style={{ width: "7rem" }}
                          onChange={this.getTableName}
                        >
                          <option value="Select option">Please Select</option>

                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="eligibility">Eligibility</option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="lcm">LCM</option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="generalFactor">
                              General Factors
                            </option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="adoption">Adoption</option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="limits">Limits</option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="taxes">Taxes</option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="fundRate">Fund Rate</option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="stateType">State Type</option>
                          )}
                          {userRole && !userRole.includes("ManualRateBeta") && (
                            <option value="emod">EMOD</option>
                          )}
                          <option value="ManualRate">ManualRate</option>

                          <option value="ManualRateCA">ManualRateCA</option>
                          {userRole &&
                            !userRole.includes("ManualRateBeta") &&
                            userRole.includes("ComplianceAdmin") && (
                              <>
                                <option value="lossCost">Loss Cost</option>
                                <option value="stateSpecific">
                                  State Specific
                                </option>
                              </>
                            )}
                        </select>
                      </div>
                    </div>

                    {tableName === "fundRate" && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <b style={{ marginTop: "10px" }}>
                          {"Instance"}
                          <span className="mandate">*</span>
                        </b>
                        <select
                          id="instance-type"
                          style={{ width: "7rem" }}
                          onChange={(e) => {
                            this.setState({
                              InstanceName: e.target.value,
                              instanceTypeRequired: false,
                            });
                          }}
                        >
                          <option selected disabled value={"Please Select"}>
                            {"Please Select"}
                          </option>
                          <option value={"libertate"}>{"Libertate"}</option>
                          <option value={"fourth"}>{"Fourth"}</option>
                        </select>
                      </div>
                    )}

                    {tableName === "lossCost" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {stateListDropDown()}
                      </div>
                    )}
                    {tableName === "eligibility" &&
                      userRole &&
                      !userRole.includes("ManualRateBeta") && (
                        <div className="mt-3 mb-4 d-flex">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <b>
                              {"Carrier"}
                              <span className="mandate">*</span>
                            </b>
                            <select
                              id="carrier-type"
                              style={{ width: "7rem" }}
                              onChange={(e) => {
                                this.setState({
                                  eligibilityCarrier: e.target.value,
                                  stateCarrierRequired: false,
                                });
                              }}
                            >
                              <option selected disabled value={"Please Select"}>
                                {"Please Select"}
                              </option>
                              {Object.keys(carrierMap).map((carrier) => {
                                return (
                                  <option value={carrier}>
                                    {carrierMap[carrier]}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            style={{
                              marginLeft: "22px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {stateListDropDown()}
                          </div>
                        </div>
                      )}
                    {tableName === "ManualRate" ||
                    tableName === "ManualRateCA" ? (
                      <ManualRate
                        tableName={tableName}
                        ManualRateGroupCheck={
                          userRole && userRole.includes("ManualRateBeta")
                        }
                      />
                    ) : (
                      ""
                    )}
                    {stateCarrierRequired && (
                      <span
                        className="required-errMsg"
                        style={{ color: "red" }}
                      >
                        {"*State and Carrier are required."}
                      </span>
                    )}
                    {instanceTypeRequired && (
                      <span
                        className="required-errMsg"
                        style={{ color: "red" }}
                      >
                        {"*Instance is required."}
                      </span>
                    )}
                    {tableName === "ManualRate" || tableName === "ManualRateCA"
                      ? " "
                      : userRole &&
                        !userRole.includes("ManualRateBeta") && (
                          <div className="text-right d-flex compliance-upload-input justify-content-between mt-3">
                            <div>
                              <input
                                className="p-0"
                                type="file"
                                name="file"
                                multiple
                                onChange={(e) => this.validateFormat(e)}
                              />
                            </div>
                            <div>
                              <button
                                type="button"
                                disabled={
                                  !validationDone || error || showLoaderUpload
                                }
                                className="btn btn-outline-primary mr-2"
                                onClick={this.uploadFile}
                              >
                                Upload
                              </button>
                              <button
                                type="button"
                                onClick={this.downloadFile}
                                className="btn btn-outline-primary"
                              >
                                Download
                              </button>
                            </div>
                            {(showLoaderUpload || showLoaderDownload) && (
                              <div className="ml-2">
                                <SmallLoader />
                              </div>
                            )}
                            {downloadLinkActive && (
                              <CSVLink
                                data={downloadDataList}
                                download={`${downloadedTableName}.csv`}
                                ref={this.downloadBtn}
                              />
                            )}
                          </div>
                        )}
                    {tableName === "ManualRate" || tableName === "ManualRateCA"
                      ? ""
                      : userRole &&
                        userRole.includes("ManualRateBeta") && (
                          <p>
                            Please the use the drop down to select Manual Rate
                            option
                          </p>
                        )}

                    {error && (
                      <>
                        <p id="upload-error">
                          {errorMessage} {listOfRowError}
                        </p>
                      </>
                    )}
                    {fileUploadSuccess && !error && (
                      <p id="upload-success">Data successfully uploaded! </p>
                    )}
                    {error && textValidation && (
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          this.downloadTxtFile(textValidation);
                        }}
                      >
                        Click here to view invalid row numbers
                      </a>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <Loader />
          </div>
        ) : (
          <AutomationTesting />
        )}

        <Footer />
      </div>
    );
  }
}

export default PanelCompliance;
